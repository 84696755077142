export default [
  {
    title: 'Partner',
    komicon: 'k-user-tick',
    children: [
      {
        title: 'Information',
        komicon: 'k-graph',
        route: 'information',
        resource: 'Partner',
        action: 'manage',
      },
      {
        title: 'Sektor Bisnis',
        komicon: 'k-home-1',
        route: 'business-category',
        resource: 'Partner',
        action: 'manage',
      },
      {
        title: 'Tipe Bisnis',
        komicon: 'k-chart-21',
        route: 'business-type',
        resource: 'Partner',
        action: 'manage',
      },
    ],
  },
]
